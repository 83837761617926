import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Taxpayer, States, State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { Card, Descriptions, Typography, Row, Col, Collapse, Button, Table, Divider, Form, Select, message } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import EditContributorForm from '../Forms/EditContributorForm';
import TableTaxes from '../Tables/TableTaxes';
import Petro from '../Icons/Petro';
import axios from 'axios';
import handlingMessage from '../../utils/handlingMessage';
const server = process.env.REACT_APP_SERVER_URL;

const TaxpayerInfo: React.FC<TaxpayerInfoProps> = ({ thm, parish, auth }) => {
  const [taxpayer, setTaxpayer] = useState<Taxpayer | null>(null);
  const [activeKey, setActiveKey] = useState('0');
  const [rim, setRim] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [doc, setDoc] = useState('');
  const [book, setBook] = useState('');
  const [contributors, setContributors] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const edit = location.pathname.includes('/dashboard/correcciones/editar/contribuyente');
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    if (!(history.location?.state as any)?.contribuyente) history.goBack();
    else setTaxpayer((history.location?.state as any)?.contribuyente);
    setContributors((history.location.state as any)?.contribuyentes);
    // eslint-disable-next-line
  }, []);

  const handleRim = (rim) => {
    setRim(rim);
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
    setRim(null);
  };

  const fetchDoc = async (id) => {
    try {
      const response = await axios.get(`${server}/settlements/search/taxPayerDoc/${id}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setDoc(response.data?.docFile?.url_archivo_recaudo);
    } catch (e) {
      // message.error(e.response?.data?.message || 'Error al conseguir los contribuyentes');
    } 
  }

  const fetchBook = async (id) => {
    try {
      const response = await axios.get(`${server}/settlements/search/taxPayerBook/${id}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setBook(response.data?.bookFile?.url_archivo);
    } catch (e) {
      // message.error(e.response?.data?.message || 'Error al conseguir los contribuyentes');
    } 
  }

  const fetchContacts = async (id) => {
    try {
      const response = await axios.get(`${server}/settlements/search/taxPayerContacts/${id}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setContacts(response.data?.contacts);
    } catch (e) {
      // message.error(e.response?.data?.message || 'Error al conseguir los contribuyentes');
    } 
  }

  const getVoucher = async (id, token) => {
    try {
      const response = await axios.post(`${server}/branches/userRegisterVoucher/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getBranchVoucher = async (id, token) => {
    try {
      const response = await axios.post(`${server}/branches/branchRegisterVoucher/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleGetVoucher = async (id: number | undefined) => {
    if(id){
      handlingMessage({
        action: () => {
          return getVoucher(id, auth?.token);
        },
        key: 'submit',
        loadingMessage: 'Realizando operacion...',
        cb: (data) => {
          if (data.status === 200) {
            const win = window.open(data.url, '_blank');
            console.log(data.url);
            win?.focus();
          }
        },
      });
    }
  };

  const handleBranchVoucher = async () => {
    if(selectedId) {   
      handlingMessage({
        action: () => {
          return getBranchVoucher(selectedId, auth?.token);
        },
        key: 'submit',
        loadingMessage: 'Realizando operacion...',
        cb: (data) => {
          if (data.status === 200) {
            const win = window.open(data.url, '_blank');
            console.log(data.url);
            win?.focus();
          }
        },
      });
    }
    else {
      message.error('Debe seleccionar una sucursal');
    }
  };

  useEffect(() => {
    if (taxpayer) {
      let auxContributor = contributors;
      const indexContributor = auxContributor.findIndex((el) => el.id === taxpayer.id);
      auxContributor[indexContributor] = taxpayer;
      setContributors(auxContributor);
      console.log(taxpayer, 'taxpayer')
      fetchDoc(taxpayer?.usuarios?.[0]?.id || 0);
      fetchBook(taxpayer?.usuarios?.[0]?.id || 0);
      fetchContacts(taxpayer?.id);
    }
    // eslint-disable-next-line
  }, [taxpayer]);

  const handleEditRif = async (data: Taxpayer) => {
    await setTaxpayer({ ...taxpayer, ...data });
    setVisible(false);
  };

  const handleEditRim = (data) => {
    if (taxpayer?.sucursales) {
      const index: number = taxpayer?.sucursales?.findIndex((el) => el.id === data?.id);
      const auxTaxpayer = taxpayer;
      if (auxTaxpayer?.sucursales) auxTaxpayer.sucursales[index] = { ...auxTaxpayer.sucursales[index], ...data };
      setTaxpayer(auxTaxpayer);
      setVisible(false);
    }
  };

  const formatNumber = (s: string) => `(${s.slice(0, 3)}) ${s.slice(3, 6)}-${s.slice(6)}`;

  const columns: ColumnsType<any> = [
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
    },
    // {
    //   title: 'Desde',
    //   dataIndex: 'desde',
    //   render: (date) => <span>{moment(date).format('DD/MM/YYYY')}</span>,
    // },
    // {
    //   title: 'Minimo Tributable',
    //   dataIndex: 'minimoTributable',
    //   render: (min) => (
    //     <span>
    //       {min} <Petro />
    //     </span>
    //   ),
    // },
  ];

  const contactColumns: ColumnsType<any> = [
    {
      title: 'Correo Electrónico',
      dataIndex: 'correo',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
    },
  ];

  return (
    <>
      <Card
        style={{ height: '100%' }}
        title={taxpayer?.esAgente === true ? 'Información del Agente de Retención' : 'Información del Contribuyente'}
        bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'scroll', border: '1px solid #f0f0f0' }}
        headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
      >
        <Descriptions title={taxpayer?.esAgente === true ? 'Datos del Agente de Retención' : 'Datos del Contribuyente'} bordered column={{ xl: 24 }}>
          <Descriptions.Item label='Documento de Identidad' span={12}>
            {taxpayer?.tipoDocumento ? `${taxpayer?.tipoDocumento}-${taxpayer?.documento}` : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Razón Social' span={12}>
            {taxpayer?.razonSocial || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Denominación Comercial' span={12}>
            {taxpayer?.denomComercial || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Siglas' span={12}>
            {taxpayer?.siglas || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Tipo' span={12}>
            {taxpayer?.tipoContribuyente || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Agente de Retencion' span={12}>
            {taxpayer?.esAgente === true ? 'Es Agente' : 'Es Contribuyente'}
          </Descriptions.Item>
          {/* {parseFloat(taxpayer?.creditoFiscal || '0') && (
            <Descriptions.Item label='Credito Fiscal'>
              Bs. {formatCurrency(parseFloat(taxpayer?.creditoFiscal || '0'))}
            </Descriptions.Item>
          )} */}
        </Descriptions>
        <Descriptions title='Ubicación' bordered style={{ marginTop: 15 }} column={{ xl: 24 }}>
          <Descriptions.Item label='Dirección' span={12}>
            {taxpayer?.direccion || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Punto de Referencia' span={12}>
            {taxpayer?.puntoReferencia || 'N/A'}
          </Descriptions.Item>
          {/* <Descriptions.Item label='Sector' span={12}>
            {taxpayer?.sector || 'N/A'}
          </Descriptions.Item> */}
          <Descriptions.Item label='Municipio' span={12}>
            {taxpayer?.parroquia ? parish.find((p) => p.id === taxpayer.parroquia)?.nombre : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Documento' span={12}>
            {doc ? (<Button target={'_blank'} download href={`${doc}`}>Documento</Button>) : 'Sin Documento' }
          </Descriptions.Item>
          <Descriptions.Item label={ taxpayer?.esAgente ? 'Libro de Control Agente de Retención' :'Libro de Timbres'} span={12}>
            {book ? (<Button target={'_blank'} download href={`${book}`}>Libro de Timbres</Button>) : (taxpayer?.esAgente ? 'Sin Libro de Control Agente de Retención' :'Sin Libro de Timbres') }
          </Descriptions.Item>
        </Descriptions>
        {taxpayer?.esAgente && contacts && contacts?.length > 0 && auth.user?.tipoUsuario !== 4 && auth.user && (
          <>
            <Divider orientation='left' style={{ marginLeft: -15, marginBottom: 10 }}>
              <Typography.Text strong style={{ color: '#000000' }}>
                Contactos Adicionales
              </Typography.Text>
            </Divider>
            <Table
              columns={contactColumns}
              dataSource={contacts}
            />
          </>
        )}
        <Row gutter={16} style={{ marginTop: '15px' }} align='middle'>
          <Button
            icon={<DownloadOutlined />}
            type='primary'
            onClick={() => handleGetVoucher(taxpayer?.id)}
          >
            Comprobante de Registro
          </Button>
        </Row>
        { taxpayer?.sucursales && taxpayer?.sucursales?.length > 0 &&
          <Row style={{ paddingLeft: 16, paddingBottom:'10px', marginTop: '15px'}} gutter={16}>
            <Col span={16}>
              <Form.Item name='rim'>
                <Select placeholder={'Seleccione la sucursal'} onChange={(value) => setSelectedId(Number(value.valueOf()))}>
                  {taxpayer?.sucursales.map((s) => 
                    <Select.Option value={s.id}>{`${s.denomComercial} - ${s.direccion}`}</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>}
          { taxpayer?.sucursales && taxpayer?.sucursales?.length > 0 &&
          <Row gutter={16} style={{ marginTop: '10px' }} align='middle'>
            <Button
              icon={<DownloadOutlined />}
              type='primary'
              onClick={() => handleBranchVoucher()}
            >
              Comprobante de Registro de Sucursal
            </Button>
          </Row>}
        {taxpayer?.liquidaciones && taxpayer.liquidaciones?.length > 0 && auth.user?.tipoUsuario !== 4 && auth.user && (
          <>
            <Divider orientation='left' style={{ marginLeft: -15, marginBottom: 10 }}>
              <Typography.Text strong style={{ color: '#000000' }}>
                {taxpayer?.esAgente === true ? 'Declaraciones' : 'Liquidaciones'}
              </Typography.Text>
            </Divider>
            <TableTaxes
              preloadedData={taxpayer.liquidaciones.map((l) => ({
                ...l,
                fecha: {
                  date: moment(l.fechaLiquidacion).utc().format('DD/MM/YYYY'),
                  month: moment(l.fechaLiquidacion).utc().format('MMMM'),
                  year: moment(l.fechaLiquidacion).utc().format('YYYY'),
                },
                ramo: l.ramo.descripcion,
                nombreCorto: l.ramo.id === 9 ? 'AE' : l.ramo.id === 8 ? 'IU' : '',
              }))}
              isAgent={taxpayer?.esAgente}
            />
          </>
        )}
        <Row gutter={16} style={{ marginTop: '15px' }} align='middle' justify='end'>
          {edit && (
            <Col>
              <Button type='primary' onClick={() => setVisible(true)}>
              {taxpayer?.esAgente === true ? 'Editar Agente de Retención' : 'Editar Contribuyente'} 
              </Button>
            </Col>
          )}
            <Button
              href={`${server}/settlements/accountStatement/${taxpayer?.id}?tipoContribuyente=${taxpayer?.tipoContribuyente}&referencia=${taxpayer?.documento}`}
              target='_blank'
              icon={<DownloadOutlined />}
              type='primary'
            >
              {taxpayer?.esAgente === true ? 'Histórico de Declaraciones' :'Estado de Cuenta'}
            </Button>
        </Row>
        {taxpayer?.sucursales && taxpayer?.sucursales?.length > 0 && (
          <React.Fragment>
            <Typography.Title level={4} style={{ fontSize: 16, marginTop: 15 }}>
            {taxpayer?.esAgente === true ? 'Información del Agente de Retención' : 'Información del Contribuyente'} 
            </Typography.Title>
            <Row>
              <Col span={24} style={{ marginTop: 20 }}>
                <Collapse activeKey={activeKey} onChange={(c) => setActiveKey(c as string)}>
                  {taxpayer?.sucursales?.map((r) => (
                    <Collapse.Panel header={`${r.referenciaMunicipal} - ${r.denomComercial}`} key={r.id} forceRender>
                      <Descriptions bordered column={{ xl: 24 }}>
                        <Descriptions.Item label='Denominación Comercial' span={12}>
                          {r.denomComercial || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label='Nombre del Representante' span={12}>
                          {r.nombreRepresentante || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label='Fecha de Aprobación' span={12}>
                          {r.fechaAprobacion ? moment(r.fechaAprobacion).format('DD/MM/YYYY') : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label='Teléfono' span={12}>
                          {r.telefono ? `+58 ${formatNumber(r.telefono)}` : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label='Correo Electrónico' span={12}>
                          {r.email || 'N/A'}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label='Monotributo' span={12}>
                          {r.esMontributo ? 'Activado' : 'Desactivado'}
                        </Descriptions.Item> */}
                        {/* <Descriptions.Item label='Estado de la Licencia' span={12}>
                          {r.estadoLicencia || 'No Definido'}
                        </Descriptions.Item> */}
                        {/* <Descriptions.Item label='Credito Fiscal por Compensación' span={12}>
                          Bs. {formatCurrency(parseFloat(r.creditoFiscal || '0'))}
                        </Descriptions.Item>
                        <Descriptions.Item label='Credito Fiscal por Retención' span={12}>
                          Bs. {formatCurrency(parseFloat(r.creditoFiscalRetencion || '0'))}
                        </Descriptions.Item> */}
                        {/* <Descriptions.Item label='Tipo de Sociedad' span={12}>
                          {r.tipoSociedad || 'No Definido'}
                        </Descriptions.Item> */}
                        <Descriptions.Item label='Municipio' span={12}>
                          {parish.find((e) => e.id === r.parroquia || e.nombre === r.parroquia)?.nombre || 'No Definido'}
                        </Descriptions.Item>
                        <Descriptions.Item label='Dirección' span={24}>
                          {r.direccion || 'No Definido'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Row gutter={16} style={{ marginTop: '15px' }} align='middle' justify='end'>
                        {edit && (
                          <Col>
                            <Button type='primary' onClick={() => handleRim(r.id)}>
                              Editar Documento de Identidad
                            </Button>
                          </Col>
                        )}
                      </Row>
                      <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10 }}>
                        <Typography.Title level={4}>Rubro</Typography.Title>
                      </Divider>
                      <Table rowKey='id' bordered pagination={false} columns={columns} dataSource={r.actividadesEconomicas} />
                      {auth.user?.tipoUsuario !== 4 && auth.user && (
                        <>
                          <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10 }}>
                            <Typography.Title level={4}>{taxpayer?.esAgente === true ? 'Declaraciones' : 'Liquidaciones'}</Typography.Title>
                          </Divider>
                          <TableTaxes
                            preloadedData={r.liquidaciones.map((l) => ({
                              ...l,
                              fecha: {
                                date: moment(l.fechaLiquidacion).utc().format('DD/MM/YYYY'),
                                month: moment(l.fechaLiquidacion).utc().format('MMMM'),
                                year: moment(l.fechaLiquidacion).utc().format('YYYY'),
                              },
                              ramo: l.ramo.descripcion,
                              nombreCorto: l.ramo.id === 9 ? 'AE' : l.ramo.id === 8 ? 'IU' : l.ramo.id === 64 ? 'SM' : '',
                            }))}
                            isAgent={taxpayer?.esAgente}
                          />
                          <Button
                            href={`${server}/settlements/accountStatement/${taxpayer.id}?tipoContribuyente=${taxpayer.tipoContribuyente}&referencia=${r.referenciaMunicipal}`}
                            target='_blank'
                            icon={<DownloadOutlined />}
                            type='primary'
                            style={{ marginTop: 10 }}
                          >
                            {taxpayer?.esAgente === true ? 'Histórico de Declaraciones' :'Estado de Cuenta'}
                          </Button>
                        </>
                      )}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Col>
            </Row>
            <Button
              onClick={() =>
                edit
                  ? history.push('/dashboard/correcciones/editar/contribuyente', { contribuyentes: contributors })
                  : history.push('/dashboard/contribuyente/consulta', { contribuyentes: contributors })
              }
              style={{ marginTop: 10 }}
            >
              Atrás
            </Button>
          </React.Fragment>
        )}
      </Card>
      {visible && edit && (
        <EditContributorForm
          modal={visible}
          rim={rim}
          taxpayer={taxpayer}
          close={() => close()}
          editRif={(data) => handleEditRif(data)}
          editRim={(data) => handleEditRim(data)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, parish: state.prsh.parish, auth: state.auth });

export default connect(mapStateToProps)(TaxpayerInfo);

interface TaxpayerInfoProps {
  thm: States.ThemeColors;
  parish: States.Parish['parish'];
  auth: States.Auth;
}
