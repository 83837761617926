import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Select, Input, Button, message, Table } from 'antd';
import { State, States, Taxpayer } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router';
import { Utils } from '../../../utils/validators';
const server = process.env.REACT_APP_SERVER_URL;

const GetAgentInfo: React.FC<GetTaxpayerInfoProps> = ({ auth, thm }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [searching, setSearching] = useState(false);
  const [taxpayers, setTaxpayers] = useState<Taxpayer[]>([]);

  const [query, setQuery] = useState('');

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if((history.location.state as any)?.contribuyentes) {
      setTaxpayers((history.location.state as any)?.contribuyentes)
    }
    //eslint-disable-next-line
  }, []);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  const columns: ColumnsType<Taxpayer> = [
    {
      title: 'Razón Social',
      dataIndex: 'razonSocial'
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      render: (doc, record) => <span>{record.tipoDocumento}-{doc}</span>
    },
    // {
    //   title: 'Tipo de Contribuyente',
    //   dataIndex: 'tipoContribuyente'
    // },
    {
      title: 'Acciones',
      render: (_, contribuyente) => (
        <Button type='primary' onClick={() => action(contribuyente)} icon={<EyeOutlined />}>
          {location.pathname.includes('certificado/SM') ?
            'Generar Solvencia':
          location.pathname.includes('certificado/IU') ?
            'Generar Solvencia':
          location.pathname.includes('licencia') ? 
            'Tramitar Licencia' : 
          location.pathname.includes('especiales') ? 
            'Liquidar' : 
            location.pathname.includes('correcciones') ? 
          'Corregir' :
          'Ver Agente'}
        </Button>
      )
    }
  ];

  const action = (contribuyente: Taxpayer) => {
    location.pathname.includes('certificado/SM') ? 
      history.push(`/dashboard/certificado/SM/generar`, { contribuyente, contribuyentes: taxpayers }) :
    location.pathname.includes('certificado/IU') ? 
      history.push(`/dashboard/certificado/IU/generar`, { contribuyente, contribuyentes: taxpayers }) :
    location.pathname.includes('solvencias/sedar') ? 
      history.push(`/dashboard/solvencias/sedar/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers }) :
    location.pathname.includes('licencia') ? 
      history.push(`/dashboard/contribuyente/licencia/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers }) :
    location.pathname.includes('especiales') ?
      history.push(`/dashboard/impuestos/especiales/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers }) :
    location.pathname.includes('correcciones') ?
      location.pathname.includes('correcciones/editar') ?
        history.push(`/dashboard/correcciones/editar/contribuyente/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers }) : 
        history.push(`/dashboard/correcciones/liquidaciones/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers }) :
    history.push(`/dashboard/contribuyente/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers })
  };

  const search = async () => {
    const values = await form.validateFields();
    const { razonSocial, doc } = values;
    if(razonSocial || doc) {
      try {
        setSearching(true);
        const params = doc && razonSocial ? `doc=${doc}&pref=${tipoDocumento}&name=${razonSocial}` : !razonSocial && doc ? `doc=${doc}&pref=${tipoDocumento}` : `name=${razonSocial}`;
        const response = await axios.get<{ contribuyentes: Taxpayer[] }>(`${server}/settlements/search/agent?${params}`,
          { headers: { Authorization: `Bearer ${auth.token}` } });
        setTaxpayers(response.data.contribuyentes);
      } catch(e) {
        message.error(e.response?.data?.message || 'Error al buscar agentes')
      } finally {
        setSearching(false);
      }
    } else {
      message.warn('Debe ingresar algún parametro para la busqueda de agentes')
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => fetchContributors(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const fetchContributors = async (query: string) => {
    let clean = query.trimStart()
    if(clean.length > 0) {
      setSearching(true);
      try {
        const response = await axios.get(`${server}/settlements/search/agentName/${query}`, { headers: { Authorization: `Bearer ${auth.token}` } });
        setTaxpayers(response.data.contribuyentes);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al conseguir los agentes');
      } finally {
        setSearching(false);
      }
    }
  };

  const fetchAllContributors = async () => {
      setSearching(true);
      try {
        const response = await axios.get(`${server}/settlements/search/allAgent`, { headers: { Authorization: `Bearer ${auth.token}` } });
        setTaxpayers(response.data.contribuyentes);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al conseguir los agentes');
      } finally {
        setSearching(false);
      }
    
  };

  return (
    <Form layout='vertical' form={form}>
      <Row gutter={24}>
        <Col xs={24} xl={7}>
          <Form.Item label='Documento de Identidad' name='doc' 
          normalize={Utils.normalize.isCNumber}
          >
            <Input placeholder='Documento de Identidad' addonBefore={tipoAddon}/>
          </Form.Item>
        </Col>
        <Col xs={24} xl={7}>
          <Form.Item label='Razón Social' name='razonSocial'>
            <Input placeholder='Razón Social' onChange={(e) => setQuery(e.target.value)}/>
          </Form.Item>
        </Col>
        <Col xl={6} xs={12}>
          <Button onClick={() => search()} style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }} loading={searching} icon={<SearchOutlined />} type='primary'>Buscar</Button>
        </Col>
        <Col xl={6} xs={12}>
          <Button onClick={() => fetchAllContributors()} style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }} loading={searching} icon={<SearchOutlined />} type='primary'>Mostrar todos</Button>
        </Col>
        <Col span={24}>
          <Table columns={columns} dataSource={taxpayers.map(t => {
            t.key = t.id;
            return t;
          })} bordered loading={searching} />
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm });

export default connect(mapStateToProps)(GetAgentInfo);

interface GetTaxpayerInfoProps {
  auth: States.Auth
  thm: States.ThemeColors
}