import React, { useEffect, useState } from 'react';
import GetTaxpayerInfo from '../../Taxpayer/components/GetTaxpayerInfo';
import {
  Card,
  Form,
  Divider,
  Typography,
  Row,
  Col,
  Select,
  Button,
  message,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import { States, State, Taxpayer } from 'sigt';
import { connect } from 'react-redux';
import axios from 'axios';
import { FileDoneOutlined } from '@ant-design/icons';
const server = process.env.REACT_APP_SERVER_URL;

const SolvencySEC: React.FC<SolvencySECProps> = ({ thm, branches, banks, institutions, auth }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [actualUrl, setActualUrl] = useState('');

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();

  const taxPayer: Taxpayer = (history.location.state as any)?.contribuyente;

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/solvencias/sec');
    }
    // eslint-disable-next-line
  }, []);

  const fetchActualUrl = async (id) => {
    try {
      const response = await axios.get(`${server}/branches/solvency/sec/${id}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setActualUrl(response.data.url);
    } catch (e) {
      console.log(e.response?.data?.message || 'Error al conseguir la solvencia');
    }
  };

  useEffect(() => {
    if (taxPayer && data.rim) {
      fetchActualUrl(data.rim);
    };
  }, [taxPayer, data.rim]);

  const submit = async () => {
    let values = await form.validateFields();
    setLoading(true);
    try {
      const data = {...values, id: taxPayer.id};
      console.log(data)
      const response = await axios.post(`${server}/branches/solvency/sec`, data, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      message.success('Solvencia creada de manera exitosa');
      if (auth?.user?.tipoUsuario !== 4) history.push('/dashboard/solvencias/sec');
      else history.push('/dashboard');
      if (response.status === 201 && response.data.url) {
        const win = window.open(response.data.url, '_blank');
        win?.focus();
      }
      form.resetFields();
      history.push('/dashboard/impuestos/pagar');
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al crear solvencia');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Solvencia ANZ-SEC-2024'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {!id && <GetTaxpayerInfo />}
      {id && (
        <Form
          form={form}
          layout='vertical'
          onFinish={submit}
          scrollToFirstError
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData({ ...v });
          }}
        >
          {(auth?.user?.tipoUsuario !== 4 ||
            (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
            <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
              <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                Sucursal
              </Typography.Title>
            </Divider>
          )}
          {(auth?.user?.tipoUsuario !== 4 ||
            (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
            <Row gutter={24}>
              <Col xs={24} xl={24}>
                <Form.Item label='Sucursal' name='rim' rules={[{ required: true, message: 'Debe ingresar la sucursal' }]}>
                  <Select placeholder='Seleccione la sucursal'>
                    {taxPayer?.sucursales?.map((s) => (
                      <Select.Option value={s.id} key={s.id}>
                        {s.referenciaMunicipal} - {s.denomComercial} - {s.email} - {s.nombreRepresentante} - {s.direccion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          {actualUrl && (
            <Row gutter={12}>
              <Col>
                <Button
                  shape='round' 
                  target='_blank' 
                  href={actualUrl} 
                  icon={<FileDoneOutlined />} 
                  type='primary'
                >
                  Descargar Solvencia más Reciente
                </Button>
              </Col>
            </Row>
          )}
          <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              {auth.user?.tipoUsuario !== 4 && (
                <Button
                  onClick={() =>
                    history.push('/dashboard/solvencias/sec', {
                      contribuyentes: (history.location.state as any)?.contribuyentes,
                    })
                  }
                >
                  Atrás
                </Button>
              )}
            </Col>
            {auth.user?.tipoUsuario !== 4 && (
              <Row gutter={12}>
                <Col>
                  <Button loading={loading} type='primary' onClick={form.submit}>
                    Generar Solvencia
                  </Button>
                </Col>
              </Row>
            )}
          </Row>
        </Form>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  branches: state.brch.branches,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps)(SolvencySEC);

interface SolvencySECProps {
  thm: States.ThemeColors;
  branches: States.Branches['branches'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
}
