import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  Divider,
  Typography,
  Row,
  Col,
  Button,
  message,
  InputNumber,
  Input,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import { States, State, Taxpayer } from 'sigt';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import GetAgentInfo from '../../Taxpayer/components/GetAgentInfo';
import { FileDoneOutlined } from '@ant-design/icons';
const server = process.env.REACT_APP_SERVER_URL;

const SolvencySEDAR: React.FC<SolvencySEDARProps> = ({ thm, branches, banks, institutions, auth }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [actualUrl, setActualUrl] = useState('');

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();

  const taxPayer: Taxpayer = (history.location.state as any)?.contribuyente;
  const months = { '0': 'Enero', '1': 'Febrero', '2': 'Marzo', '3': 'Abril', '4': 'Mayo', '5': 'Junio', '6': 'Julio', '7': 'Agosto', '8': 'Septiembre', '9': 'Octubre', '10': 'Noviembre', '11': 'Diciembre' };

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/solvencias/sedar');
    }
    // eslint-disable-next-line
  }, []);

  const fetchActualUrl = async (id) => {
    try {
      const response = await axios.get(`${server}/branches/solvency/sedar/${id}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setActualUrl(response.data.url);
    } catch (e) {
      console.log(e.response?.data?.message || 'Error al conseguir la solvencia');
    }
  };

  useEffect(() => {
    if (taxPayer) {
      fetchActualUrl(taxPayer.id);
    };
  }, [taxPayer]);

  const firstListItem = (fields, add) => {
    if(fields.length === 0) {
      add();
      add();
      add();
    }
  };

  const getMonthName = (index: number) => {
    const monthDate = moment().subtract(index+1,'month');
    return months[monthDate.month()];
  }

  const submit = async () => {
    let values = await form.validateFields();
    setLoading(true);
    try {
      const data = {...values, id: taxPayer.id};
      console.log(data)
      const response = await axios.post(`${server}/branches/solvency/sedar`, data, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      message.success('Solvencia creada de manera exitosa');
      if (auth?.user?.tipoUsuario !== 4) history.push('/dashboard/solvencias/sedar');
      else history.push('/dashboard');
      if (response.status === 201 && response.data.url) {
        const win = window.open(response.data.url, '_blank');
        win?.focus();
      }
      form.resetFields();
      history.push('/dashboard/impuestos/pagar');
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al crear solvencia');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Solvencia ANZ-SEDAR-2024'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {!id && <GetAgentInfo />}
      {id && (
        <Form
          form={form}
          layout='vertical'
          onFinish={submit}
          scrollToFirstError
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData({ ...v });
          }}
        >
          {actualUrl && (
            <Row gutter={12}>
              <Col>
                <Button
                  shape='round' 
                  target='_blank' 
                  href={actualUrl} 
                  icon={<FileDoneOutlined />} 
                  type='primary'
                >
                  Descargar Solvencia más Reciente
                </Button>
              </Col>
            </Row>
          )}
          <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Detalles
            </Typography.Title>
          </Divider>
          <Row gutter={24}>
            <Form.List name='meses'>
              {(fields, { add, remove }) => (
                <Col xs={24} xl={24}>
                  {firstListItem(fields, add)}
                  {fields.map((field, index) => { 
                    return (
                    <React.Fragment key={field.key}>
                      <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
                        <Typography.Text strong style={{ marginLeft: 5 }}>
                          {`Mes Nro ${index + 1}`}
                        </Typography.Text>
                      </Divider>
                      <Form.Item required key={field.key} style={{ width: '100%', margin: 0 }}>
                        <Row gutter={24} style={{ padding: '0 20px' }}>
                          <Col xs={24} xl={12}>
                            <Form.Item 
                              label='Nombre del mes' 
                              name={[field.name, 'nombre']} 
                              fieldKey={field.fieldKey} 
                              rules={[{ required: true, message: 'Debe ingresar el nombre del mes' }]}
                            >
                              <Input placeholder='Nombre del mes' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    </React.Fragment>
                  )})}
                </Col>
              )}
            </Form.List>
          </Row>
          <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              {auth.user?.tipoUsuario !== 4 && (
                <Button
                  onClick={() =>
                    history.push('/dashboard/solvencias/sedar', {
                      contribuyentes: (history.location.state as any)?.contribuyentes,
                    })
                  }
                >
                  Atrás
                </Button>
              )}
            </Col>
            {auth.user?.tipoUsuario !== 4 && (
              <Row gutter={12}>
                <Col>
                  <Button loading={loading} type='primary' onClick={form.submit}>
                    Generar Solvencia
                  </Button>
                </Col>
              </Row>
            )}
          </Row>
        </Form>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  branches: state.brch.branches,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps)(SolvencySEDAR);

interface SolvencySEDARProps {
  thm: States.ThemeColors;
  branches: States.Branches['branches'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
}
