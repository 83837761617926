import { Button, Col, Form, message, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import axios from 'axios';
import { useHistory } from 'react-router';
import handlingMessage from '../../utils/handlingMessage';
const server = process.env.REACT_APP_SERVER_URL;

const BranchVoucherDownload = ({ thm, auth }) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const getVoucher = async (id, token) => {
    try {
      const response = await axios.post(`${server}/branches/branchRegisterVoucher/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async () => {
    if(selectedId) {
      setLoading(true);
      handlingMessage({
        action: () => {
          return getVoucher(selectedId, auth?.token);
        },
        key: 'submit',
        loadingMessage: 'Realizando operacion...',
        cb: (data) => {
          if (data.status === 200) {
            const win = window.open(data.url, '_blank');
            console.log(data.url);
            win?.focus();
          }
          setLoading(false);
        },
      });
    }
    else {
      message.error('Debe seleccionar una sucursal');
    }
  };

  return (
    <React.Fragment>
      <Modal
        width='600px'
        bodyStyle={{ height: '45vh', overflowY: 'scroll' }}
        centered
        visible={modalVisible}
        footer={null}
        closable
        title='Descargar Comprobante de Registro'
        maskClosable
        onCancel={() => history.goBack()}
      >
        <Form form={form} layout='vertical' onFinish={handleSubmit}>
        { auth?.user?.contribuyente?.sucursales && auth?.user?.contribuyente?.sucursales?.length > 0 &&
          <Row style={{ paddingLeft: 16, paddingBottom:'10px' }} gutter={16}>
            <Col span={16}>
              <Form.Item name='rim'>
                <Select placeholder={'Seleccione la sucursal'} onChange={(value) => setSelectedId(Number(value.valueOf()))}>
                  {auth?.user?.contribuyente?.sucursales.map((s) => 
                    <Select.Option value={s.id}>{`${s.denomComercial} - ${s.direccion}`}</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>}
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Button type='primary' htmlType='submit' loading={loading}>
                Descargar 
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(BranchVoucherDownload);
