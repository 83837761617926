import React, { useEffect, useState } from 'react';
import { fetchAgreements, payAgreement, setAgreements } from '../../../redux/actions/agreements';
import {
  Card,
  Table,
  Tooltip,
  Button,
  Modal,
  Form,
  message,
  Tag,
  Typography,
  Select,
  Row,
  Col,
  Input,
  Spin,
  Checkbox,
  InputNumber,
  DatePicker,
} from 'antd';
import { States, State, AgreementPart, Agreement } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import { CreditCardOutlined, SearchOutlined, LoadingOutlined, FileDoneOutlined, EditFilled } from '@ant-design/icons';
import moment from 'moment';
import MultipleWireTransfers from '../../Forms/components/MultipleWireTransfers';
import axios, { AxiosResponse } from 'axios';
import { cloneDeep, round } from 'lodash';
import MultipleTransactions from '../../Forms/components/MultipleTransactions';
import { ColumnsType } from 'antd/lib/table';
import Petro from '../../Icons/Petro';
import { Utils } from '../../../utils/validators';
const server = process.env.REACT_APP_SERVER_URL;

const formatCurrency = (number: number | string) => {
  number = typeof number !== 'number' ? parseFloat(number) : number;
  return new Intl.NumberFormat('de-DE').format(Math.round((number + Number.EPSILON) * 100) / 100);
};

const AgreementTaxPayer: React.FC<AgreementTaxPayerProps> = ({
  thm,
  agrm,
  auth,
  institutions,
  petro,
  banks,
  fetchAgreements,
  payAgreement,
  setAgreements,
}) => {
  const [userType, setUserType] = useState('JURIDICO');
  const [data, setData] = useState<any>({});
  const { width } = useWindowDimensions();
  const [selectedFragment, setSelectedFragment] = useState<AgreementPart>();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [payForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [downloading, setDownloading] = useState(false);
  const [conInteres, setConInteres] = useState(false);
  const [conRecargo, setConRecargo] = useState(false);
  const [files, setFiles] = useState<{ [P: string]: File } | null>(null);
  const [modifyForm] = Form.useForm();
  const [modifyModal, setModifyModal] = useState(false);

  const canModify: number[] = [90, 98, 107];
  const canPay = [42, 25, 22, 40, 41, 90, 98, 100, 104, 107];
  const ramosBomberos: number[] = [236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 441];
  const ut = useSelector((state: State) => state.ut.ut);

  const selectedInstitution = institutions?.find((i) => i.id === 9);

  useEffect(() => {
    console.log(agrm.agreements);
  }, [agrm.agreements]);

  const handleFragment = (fragment) => {
    if (fragment) {
      let newFragment = { ...fragment };
      const agreement = agrm?.agreements?.find((a) => a.id === fragment.idConvenio);
      let nuevoMonto = round(Number(fragment.montout) * round((petro * ut) / 100, 2), 2);
      let interes = 0;
      let recargo = 0;
      if (conInteres === true) {
        interes = agreement?.interes ? Number(agreement?.interes) : 0;
      }
      if (conRecargo === true) {
        recargo = round(
          ((agreement?.montout ? round(Number(agreement?.montout) * round((petro * ut) / 100, 2), 2) : 0) + interes) * 0.1,
          2
        );
      }
      newFragment.monto = round(nuevoMonto + interes + recargo, 2);
      setSelectedFragment(newFragment);
    }
  };

  useEffect(() => {
    if (selectedFragment) {
      let newFragment = { ...selectedFragment };
      const agreement = agrm?.agreements?.find((a) => a.id === selectedFragment.idConvenio);
      let nuevoMonto = round(Number(selectedFragment.montout) * round((petro * ut) / 100, 2), 2);
      let interes = 0;
      let recargo = 0;
      console.log(agreement);
      if (conInteres === true) {
        interes = agreement?.interes ? Number(agreement?.interes) : 0;
      }
      if (conRecargo === true) {
        recargo = round(
          ((agreement?.montout ? round(Number(agreement?.montout) * round((petro * ut) / 100, 2), 2) : 0) + interes) * 0.1,
          2
        );
      }
      newFragment.monto = round(nuevoMonto + interes + recargo, 2);
      setSelectedFragment(newFragment);
    }
    //eslint-disable-next-line
  }, [conInteres, conRecargo]);

  useEffect(() => {
    if (auth.user?.tipoUsuario === 4) fetchAgreements();
    return () => setAgreements([]);
    //eslint-disable-next-line
  }, [auth]);

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const docTypeSelect = (
    <Form.Item noStyle name='docType'>
      <Select>
        {/* {userType !== 'JURIDICO' ? null : 
          <>
            <Select.Option value='J'>J</Select.Option>
            <Select.Option value ='G'>G</Select.Option>
          </>
        } */}
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
        <Select.Option value='C'>C</Select.Option>
      </Select>
    </Form.Item>
  );

  const onSearchTaxPayer = async () => {
    const values = await searchForm.validateFields();
    const key = 'search';
    message.loading({ content: 'Buscando contribuyente...', key, duration: 0 });
    setLoading(true);
    try {
      let response: AxiosResponse<{ convenios: Agreement[]; message: string }> = await axios.get(
        `${server}/settlements/agreements/${values.userType}?documento=${values.document}&tipoDocumento=${values.docType}&referencia=${values.document}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setAgreements(response.data.convenios);
      searchForm.resetFields();
      message.success({ content: response.data?.message || 'Busqueda exitosa!', key, duration: 2.5 });
    } catch (e) {
      message.error({
        content: e?.response?.data?.error || e?.response?.data?.message || 'Error en el servidor',
        key,
        duration: 2.5,
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadTicket = async (idFragment: number) => {
    setDownloading(true);
    message.loading({ message: 'Generando comprobante...', key: 'receipt' });
    try {
      const response = await axios.post(`${server}/settlements/receipt/${idFragment}/agreement`, null, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      message.success({ message: 'Comprobante generado de manera exitosa.', key: 'receipt' });
      window.open(response.data.url, '_blank');
    } catch (e) {
      message.error({ message: 'Error al generar comprobante para la solicitud.', key: 'receipt' });
    }
    setDownloading(false);
  };

  const modifyTicket = async () => {
    setDownloading(true);
    message.loading({ message: 'Generando comprobante...', key: 'receipt' });
    try {
      let values = await modifyForm.validateFields();
      values = {...values, ute: values.montoPetro, bs: values.monto}
      const response = await axios.post(`${server}/settlements/receipt/${selectedFragment?.id}/agreement/custom`, values, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      message.success({ message: 'Comprobante modificado de manera exitosa.', key: 'receipt' });
      modifyForm.resetFields();
      setModifyModal(false);
      window.open(response.data.url, '_blank');
    } catch (e) {
      message.error({ message: 'Error al modificar comprobante para la solicitud.', key: 'receipt' });
    }
    setDownloading(false);
  };

  const Search = (
    <Form
      form={searchForm}
      layout='vertical'
      style={{ padding: width < 992 ? '0 10px' : '0px 20px', marginBottom: 16 }}
      onFinish={onSearchTaxPayer}
    >
      <Row gutter={24}>
        {loading && (
          <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spin
              tip='Consultando contribuyente...'
              indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
            />
          </Col>
        )}
        {!loading && (
          <React.Fragment>
            <Col xl={7} xs={24}>
              <Form.Item
                label='Tipo de Contribuyente'
                name='userType'
                rules={[{ required: true, message: 'Debe ingresar el tipo de contribuyente' }]}
              >
                <Select placeholder='Tipo de Contribuyente' onChange={(value: string) => setUserType(value)}>
                  <Select.Option value='NATURAL'>Natural</Select.Option>
                  <Select.Option value='JURIDICO'>Jurídico</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={7}>
              <Form.Item
                label='Documento de Identidad'
                name='document'
                rules={[{ required: true, message: 'Debe ingresar el documento de identidad del contribuyente' }]}
                normalize={Utils.normalize.isCNumber}
              >
                <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
              </Form.Item>
            </Col>
            {/* <Col xs={24} xl={7}>
            <Form.Item name='rim' label='Documento de Identidad' rules={[{ required: userType === 'JURIDICO', message: 'Debe ingresar un Documento de Identidad' }]}>
              <Input placeholder='Referencia Municipal' />
            </Form.Item>
          </Col> */}
            <Col xl={3} xs={12}>
              <Button
                style={{ marginTop: width < 1200 ? 0 : 40, width: '100%' }}
                loading={loading}
                type='primary'
                htmlType='submit'
                icon={<SearchOutlined />}
              >
                Buscar
              </Button>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </Form>
  );

  const onSubmitPayment = async () => {
    let values = await payForm.validateFields();
    let pagos = Array.isArray(payForm.getFieldValue('pagos')) ? cloneDeep(payForm.getFieldValue('pagos')) || [] : [];
    pagos
      .filter((p) => p)
      .forEach((p) => {
        Object.keys(p).forEach((k) => {
          if (moment.isMoment(p[k])) {
            p[k] = p[k].toISOString();
          }
        });
      });

    const checkF = (i) => {
      return files ? files.hasOwnProperty(`Comprobante${i + 1}`) : false;
    };
    let notP: Array<any> = [];
    if (pagos && pagos?.length > 0) {
      pagos.map((p, i) => {
        p.i = i;
        p.idInstitucionBanco = p.destino;
        p.destino = banks?.find(
          (b) => b.id === selectedInstitution?.cuentasBancarias?.find((c) => c.id === p.destino)?.banco
        )?.id;
        return p;
      });
      let filterP = pagos.filter((p) => p.metodoPago !== 'PUNTO DE VENTA');
      if (filterP && filterP.length > 0) {
        filterP.map((p, i) => {
          p.check = checkF(p.i);
          notP.push(p);
        });
      }
    }
    const hasAllMandatoryTakings =
      auth?.user?.tipoUsuario === 4 ? (notP && notP?.length > 0 ? !notP.some((p, i) => p.check === false) : true) : true;
    if (!hasAllMandatoryTakings && auth?.user?.tipoUsuario === 4) {
      message.error('Debe ingresar un comprobante por cada pago');
    }

    if (
      round(+pagos.map((p) => p.costo).reduce((c, v) => c + v, 0), 2) <
      +round(
        selectedFragment?.monto ? Number(selectedFragment?.monto) : +(parseFloat(selectedFragment?.montoPetro || '0') * petro),
        2
      ).toFixed(2)
    )
      return message.error('La suma de los montos de las transacciones debe ser igual o mayor al total de la fracción');
    setLoading(true);
    const complete = message.loading('Agregando pago para la fraccion');
    try {
      const response = await axios.put(
        `${server}/settlements/${selectedFragment?.idConvenio}/payment/${selectedFragment?.id}`,
        { procedimiento: { pagos, conInteres: conInteres, conRecargo: conRecargo } },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      payAgreement(response.data.solicitud);
      message.success('Pago registrado de manera exitosa');
      payForm.resetFields();
      setModal(false);
    } catch (e) {
      message.error(
        e.response?.data?.error || e.response?.data?.message
          ? (e.response.data.message as string).includes('referencia')
            ? 'La referencia bancaria ya ha sido utilizada.'
            : e.response.data.message
          : 'Error al crear el pago'
      );
    } finally {
      setLoading(false);
      complete();
    }
  };

  const expandedRowRender = ({ porciones, id }) => {
    const columns: ColumnsType<any> = [
      { title: 'Número', dataIndex: 'porcion', render: (porcion, record) => <span>{`${porcion}/${record.cantidad}`}</span> },
      { title: 'Monto VTCMMV', dataIndex: 'montout', render: (monto) => <span>{Number(monto)}</span> },
      { title: 'Fecha limite', dataIndex: 'fecha', key: 'fecha', render: (value) => moment(value).format('DD-MM-YYYY') },
      {
        title: 'Estado',
        dataIndex: 'estado',
        align: 'center',
        render: (value) => (
          <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(value).color}>
            {getState(value).name}
          </Tag>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'actions',
        render: (id, record) =>
          record.estado === 'ingresardatos' ? (
            auth.user?.tipoUsuario !== 4 && canPay.includes(auth.user?.institucion?.cargo?.id || 0) ? (
              <Tooltip title='Pagar'>
                <Button
                  size='middle'
                  type='primary'
                  icon={<CreditCardOutlined />}
                  onClick={() => {
                    handleFragment(porciones.find((e) => e.id === id));
                    setModal(true);
                  }}
                >
                  {width > 440 ? 'Pagar' : null}
                </Button>
              </Tooltip>
            ) : null
          ) : (
            <Row>
              <Col>
                <Tooltip title='Comprobante'>
                  <Button
                    size='middle'
                    type='primary'
                    icon={<FileDoneOutlined />}
                    loading={downloading}
                    onClick={() => downloadTicket(id)}
                  >
                    {width > 440 ? 'Comprobante' : null}
                  </Button>
                </Tooltip>
              </Col>
              {canModify.includes(auth.user?.institucion?.cargo?.id || 0) &&
              <Col style={{paddingLeft:'10px'}}>
                <Tooltip title='Modificar'>
                  <Button
                    size='middle'
                    type='primary'
                    icon={<FileDoneOutlined />}
                    loading={downloading}
                    onClick={() => {
                      setSelectedFragment(porciones.find((e) => e.id === id));
                      setModifyModal(true);
                    }}
                  >
                    {width > 440 ? 'Modificar' : null}
                  </Button>
                </Tooltip>
              </Col>}
            </Row>
          ),
      },
    ];

    return (
      <Table
        bordered={false}
        size='middle'
        style={{ marginLeft: 83, width: '100%' }}
        key={id}
        rowKey={({ idConvenio }) => idConvenio}
        columns={columns}
        dataSource={porciones}
        pagination={false}
      />
    );
  };

  const columns = [
    { title: 'Razon Social', dataIndex: 'contribuyente', key: 'contribuyente', render: (value) => value.razonSocial },
    {
      title: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      render: (value, record) => `${record.tipoDocumento}-${value}`,
    },
    // { title: 'Documento de Identidad', dataIndex: 'referenciaMunicipal', key: 'referenciaMunicipal' },
    { title: 'Ramo', dataIndex: 'ramo', key: 'ramo', width: 300 },
    { title: 'Fecha', dataIndex: 'fecha', key: 'fecha', render: (value) => moment(value).format('DD-MM-YYYY') },
    { title: 'Monto VTCMMV', dataIndex: 'montout', render: (monto) => <span>{Number(monto)}</span> },
  ];

  return (
    <Card
      style={{ height: '100%' }}
      title='Convenios'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {auth.user?.tipoUsuario !== 4 && Search}
      <Table
        scroll={{ x: 'auto' }}
        rowKey={({ id }) => id}
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={agrm.agreements}
        loading={loading}
      />
      <Modal
        bodyStyle={{ padding: 50, height: '80vh', overflowY: 'scroll' }}
        centered
        confirmLoading={loading}
        width={900}
        maskClosable
        visible={modal}
        onCancel={() => setModal(false)}
        onOk={onSubmitPayment}
      >
        <Typography.Text strong>
          El monto de la fracción de pago seleccionada tiene un total en VTCMMV de{' '}
          <Typography.Text strong type='danger'>
            {Number(selectedFragment?.montout).toFixed(2)}
          </Typography.Text>
          , y debe cancelar el equivalente de <br />
          <Typography.Text strong type='danger'>
            Bs. {selectedFragment?.monto || 0}.{' '}
          </Typography.Text>
          El monto de la(s) transaccion(es) debe ser igual o mayor al total de pagar y deben ser realizadas desde el mismo banco.
        </Typography.Text>
        <br></br>
        <Checkbox checked={conInteres} onChange={(e) => setConInteres(!conInteres)}>
          Incluir Tasa de Interes
        </Checkbox>
        <br></br>
        <Checkbox checked={conRecargo} onChange={(e) => setConRecargo(!conRecargo)}>
          Incluir Tasa de Recargo
        </Checkbox>
        <Form
          layout='vertical'
          style={{ marginTop: 20, marginBottom: 16 }}
          form={payForm}
          initialValues={{ metodoPago: 'TRANSFERENCIA' }}
          onValuesChange={(c, v) => {
            searchForm.setFieldsValue(v);
            setData(v);
          }}
        >
          {auth.user?.tipoUsuario === 3 || auth.user?.tipoUsuario === 2 || auth.user?.tipoUsuario === 5 ? (
            <MultipleTransactions
              selectedInstitution={selectedInstitution}
              form={payForm}
              data={data}
              files={files}
              setFiles={setFiles}
              percentTax={false}
              mineralTax={false}
              bomberos={false}
              peajes={false}
            />
          ) : (
            <MultipleWireTransfers selectedInstitution={selectedInstitution} form={payForm} bomberos={false} peajes={false} />
          )}
        </Form>
      </Modal>
      <Modal
        visible={modifyModal}
        onCancel={() => setModifyModal(false)}
        title='Modificar Comprobante'
        width={width < 992 ? '100%' : '40%'}
        okButtonProps={{ icon: <EditFilled />, title: 'Modificar', loading }}
        onOk={() => modifyTicket()}
      >
        <Form layout='vertical' initialValues={{...selectedFragment, subtotal:selectedFragment?.monto, fecha: moment(selectedFragment?.fechaAprobacion || selectedFragment?.fecha), pagos: selectedFragment?.pagos?.map((p) => {p.fechaDePago = moment(p.fechaDePago); return p})}} form={modifyForm}>
          <Row>
            <Col xl={12} xs={12}>
              <Form.Item label='Monto MMV' name='montoPetro'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={12}>
              <Form.Item label='Monto Bs' name='monto'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12} xs={12}>
              <Form.Item label='Subtotal Bs' name='subtotal'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={12} xs={12}>
              <Form.Item label='Interes' name='interes'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
            <Col xl={12} xs={12}>
              <Form.Item label='Recargo' name='recargo'>
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} xl={12}>
            <Form.Item label='Fecha' name='fecha' rules={[{ required: false}]}>
              <DatePicker placeholder='Fecha' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Form.List name='pagos'>
          {(fields, { add, remove }) => (
          <div>
            {fields.map((field, index) => { 
               return (
            <Row gutter={24}>
              <Row>
                <Typography.Text>{`Pago ${index+1}`}</Typography.Text>
              </Row>
              <Row>
                <Col xs={12} xl={12}>
                  <Form.Item
                    label='Referencia'
                    name={[field.name, 'referencia']}
                    fieldKey={field.fieldKey}
                    rules={[{ required: true, message: 'Debe ingresar la referencia' }]}
                  >
                    <Input placeholder='Referencia' maxLength={30} disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} xl={12}>
                  <Form.Item
                    label='Fecha de Pago'
                    name={[field.name, 'fechaDePago']}
                    fieldKey={field.fieldKey}
                    rules={[{ required: false}]}
                  >
                    <DatePicker placeholder='Fecha de Pago' style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={12}>
                  <Form.Item 
                    label='Monto'  
                    name={[field.name, 'monto']}
                    fieldKey={field.fieldKey}
                    rules={[{ required: false}]}>
                    <Input placeholder='Monto' />
                  </Form.Item>
                </Col>
              </Row>
            </Row>)})}
            </div>)}
          </Form.List>
        </Form>
      </Modal>
    </Card>
  );
};

const mapStateToProps = ({ thm, agrm, inst, auth, coin, bk }: State) => ({
  thm,
  agrm,
  petro: coin.petro,
  banks: bk.banks,
  institutions: inst.institutions,
  auth,
});

export default connect(mapStateToProps, { fetchAgreements, payAgreement, setAgreements })(AgreementTaxPayer);

interface AgreementTaxPayerProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  agrm: States.Agreements;
  institutions: States.Institutions['institutions'];
  petro: States.Coins['petro'];
  banks: States.Banks['banks'];
  fetchAgreements: () => Promise<void>;
  payAgreement: (fragment: AgreementPart) => void;
  setAgreements: (agreements: Agreement[]) => void;
}
